import React, { useState } from "react";
import { ReloadIcon, RocketIcon } from "@radix-ui/react-icons";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";

export default function RegistrationForm() {
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [formData, setFormData] = useState({
    organization: "",
    designation: "",
    name: "",
    email: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    phone: "",
    t_shirt_size: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^(?:\+91|0)?[6-9]\d{9,10}$/; // Indian phone number validation
    return phoneRegex.test(phone);
  };

  const validatePinCode = (pincode) => {
    const pinCodeRegex = /^\d{6}$/; // Indian PIN code validation
    return pinCodeRegex.test(pincode);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email validation regex
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDone(false);
    setStatus("");

    if (!validatePhoneNumber(formData.phone)) {
      setStatus("Invalid phone number.");
      return;
    }

    if (!validatePinCode(formData.pincode)) {
      setStatus("Invalid PIN code. It should be a 6-digit Indian PIN code.");
      return;
    }

    if (!validateEmail(formData.email)) {
      setStatus("Invalid email address.");
      return;
    }

    setIsLoading(true);
    try {
      let apiUrl = "http://localhost:5010/api/v1";
      if (window.location.hostname !== "localhost") {
        apiUrl = "https://registration.myforms.info/api/v1";
      }
      console.log("api url : ", apiUrl);
      const response = await fetch(`${apiUrl}/main/form_aug_24`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      setIsLoading(false);
      if (!response.ok) {
        setStatus("Error in form submission");
        throw new Error(`HTTP Error! Status: ${response.status}`);
      } else {
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.success) {
          setDone(true);
        } else if (!responseData.success) {
          setStatus(responseData.error);
        }
      }
    } catch (error) {
      console.log("error in sending form data : ", error);
      setIsLoading(false);
      setStatus("Error in form submission");
    }
  };

  return (
    <div className="w-[96%] sm:w-[640px] mx-auto">
      <div className="mt-5 shadow-md rounded-md">
        <img
          alt="Illustration"
          className="w-full h-72 rounded-md"
          src="https://ksk-forceonekl.blr1.digitaloceanspaces.com/comm_vault_header.png"
        />
      </div>
      <form
        className="space-y-4 p-5 sm:p-10 bg-white shadow-md rounded-md mt-5 mb-10"
        onSubmit={(e) => handleSubmit(e)}
      >
        <h1 className="text-3xl mb-8 text-center">Registration Form</h1>
        <div className="space-y-2">
          <Label htmlFor="organization">Organization *</Label>
          <Input
            id="organization"
            type="text"
            placeholder="Organization ..."
            required={true}
            name="organization"
            value={formData.organization}
            onChange={handleChange}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="designation">Designation *</Label>
          <Input
            id="designation"
            type="text"
            placeholder="Designation ..."
            required={true}
            name="designation"
            value={formData.designation}
            onChange={handleChange}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="name">Name *</Label>
          <Input
            id="name"
            type="text"
            placeholder="Full name ..."
            required={true}
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="email">Email *</Label>
          <Input
            id="email"
            type="email"
            placeholder="Email ..."
            required={true}
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="address">Address *</Label>
          <Input
            id="address"
            type="text"
            placeholder="Address ..."
            required={true}
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="pincode">PIN Code *</Label>
          <Input
            id="pincode"
            type="text"
            placeholder="PIN code ..."
            required={true}
            name="pincode"
            value={formData.pincode}
            onChange={handleChange}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="city">City *</Label>
          <Input
            id="city"
            type="text"
            placeholder="City ..."
            required={true}
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="state">State *</Label>
          <Input
            id="state"
            type="text"
            placeholder="State ..."
            required={true}
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="phone">Phone number *</Label>
          <Input
            id="phone"
            type="text"
            placeholder="Phone ..."
            required={true}
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="t_shirt_size">Select T-Shirt Size *</Label>
          <Select
            onValueChange={(value) => {
              setFormData({ ...formData, t_shirt_size: value });
            }}
            required={true}
            name="t_shirt_size"
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select T-Shirt Size" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="S">S</SelectItem>
              <SelectItem value="M">M</SelectItem>
              <SelectItem value="L">L</SelectItem>
              <SelectItem value="XL">XL</SelectItem>
              <SelectItem value="XXL">XXL</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="mt-5">
          <Label>* All fields are mandatory</Label>
        </div>
        {done && (
          <Alert variant="success">
            <RocketIcon className="h-4 w-4" />
            <AlertTitle>Success</AlertTitle>
            <AlertDescription>
              Thank you for your registration.
            </AlertDescription>
          </Alert>
        )}
        {status && (
          <Alert variant="destructive">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{status}</AlertDescription>
          </Alert>
        )}
        <div className="w-full text-center ">
          <Button className="w-1/2 mt-5">
            {isLoading && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
