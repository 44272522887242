import React from "react";
import RegistrationForm from "./components/registration-form";
import { Route, Routes } from "react-router-dom";
import PuneRegistrationForm from "./components/pune-registration-form";
import KolkataRegistrationForm from "./components/kolkata-registration-form";

function App() {
  return (
    <Routes>
      <Route path="/" element={<RegistrationForm />} />
      <Route path="/pune" element={<PuneRegistrationForm />} />
      <Route path="/kolkata" element={<KolkataRegistrationForm />} />
    </Routes>
  );
}

export default App;
